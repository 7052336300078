import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import CropFree from '@material-ui/icons/CropFree'
import LocalShipping from '@material-ui/icons/LocalShipping'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import Storefront from '@material-ui/icons/Storefront'
import Lock from '@material-ui/icons/Lock'
import Backup from '@material-ui/icons/Backup'
import Palette from '@material-ui/icons/Palette'
import SettingsOverscan from '@material-ui/icons/SettingsOverscan'
import Receipt from '@material-ui/icons/Receipt'
import LocalOffer from '@material-ui/icons/LocalOffer'
import { ToolbarSpacer } from './Utils'
import { useRouter } from 'next/router'
import { Spacer } from '@/components/shared/Spacer'
import { useLogout } from '@/hooks/auth'

interface MenuProps {
  isOpen: boolean
  onClose(): void
}

export const Menu: React.FC<MenuProps> = (props) => {
  const router = useRouter()
  const logout = useLogout()

  const navigateTo = (path) => () => {
    router.push(path)
    props.onClose()
  }

  return (
    <Drawer anchor="left" open={props.isOpen} onClose={props.onClose}>
      <ToolbarSpacer />
      <List
        style={{
          minWidth: '200px',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <ListItem button onClick={navigateTo('/invoices')}>
          <ListItemIcon>
            <ShoppingCart />
          </ListItemIcon>
          <ListItemText primary="Invoices" />
        </ListItem>
        <ListItem button onClick={navigateTo('/item-checker')}>
          <ListItemIcon>
            <SettingsOverscan />
          </ListItemIcon>
          <ListItemText primary="Item checker" />
        </ListItem>
        <ListItem button onClick={navigateTo('/barcodes')}>
          <ListItemIcon>
            <CropFree />
          </ListItemIcon>
          <ListItemText primary="Barcodes Generator" />
        </ListItem>
        <ListItem button onClick={navigateTo('/quickbooks-to-site')}>
          <ListItemIcon>
            <Storefront />
          </ListItemIcon>
          <ListItemText primary="QB to Site" />
        </ListItem>
        <ListItem button onClick={navigateTo('/tags-remover')}>
          <ListItemIcon>
            <LocalOffer />
          </ListItemIcon>
          <ListItemText primary="Tags remover" />
        </ListItem>
        <ListItem button onClick={navigateTo('/color-association')}>
          <ListItemIcon>
            <Palette />
          </ListItemIcon>
          <ListItemText primary="Color Associations" />
        </ListItem>
        <ListItem button onClick={navigateTo('/backups')}>
          <ListItemIcon>
            <Backup />
          </ListItemIcon>
          <ListItemText primary="Backups" />
        </ListItem>
        <ListItem button onClick={navigateTo('/upc-codes')}>
          <ListItemIcon>
            <LocalShipping />
          </ListItemIcon>
          <ListItemText primary="UPC Codes" />
        </ListItem>
        <ListItem button onClick={navigateTo('/invoice-importer')}>
          <ListItemIcon>
            <Receipt />
          </ListItemIcon>
          <ListItemText primary="Import invoices" />
        </ListItem>
        <Spacer />
        <ListItem
          button
          onClick={() => {
            logout()
            props.onClose()
            window.location.reload()
          }}
        >
          <ListItemIcon>
            <Lock />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Drawer>
  )
}
