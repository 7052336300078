import React from 'react'
import { Button } from '@material-ui/core'
import { getLoginUrl } from '@/api-client/auth'
import { useIsLoggedIn } from '@/hooks/auth'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({}))
export const QuickbookLogin: React.FC = (props) => {
  const styles = useStyles()
  const isLoggedIn = useIsLoggedIn()

  const onLogin = async () => {
    const url = await getLoginUrl()
    window.location.href = url
  }

  return isLoggedIn ? (
    <Button color={'primary'} variant="text" onClick={onLogin}>
      Logout
    </Button>
  ) : (
    <Button color={'primary'} variant="text" onClick={onLogin}>
      Login with quickbook
    </Button>
  )
}
