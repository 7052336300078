import React, {useEffect, useMemo, useState} from 'react'
import {getAuth, GoogleAuthProvider, signInWithRedirect, getRedirectResult} from "firebase/auth";
import {useAuthState} from 'react-firebase-hooks/auth';

const provider = new GoogleAuthProvider();


export const LoginGate: React.FC<{}> = ({children}) => {

    const auth = useMemo(() => getAuth(), [])
    const [user, loading] = useAuthState(auth);
    const [ready, setReady] = useState(false)
    const [checkedForRedirect, setCheckedForRedirect] = useState(false)

    useEffect(() => {
        if (!checkedForRedirect) return
        if (!loading && !user) {
            signInWithRedirect(auth, provider)
        } else if (!loading && !!user) {
            user.getIdToken().then(idToken => {
                localStorage.setItem('gtoken', idToken)
                setReady(true)
            })
        }
    }, [user, loading, checkedForRedirect])

    useEffect(() => {
        getRedirectResult(auth).then(result => {
            if (result) {
                const credential = GoogleAuthProvider.credentialFromResult(result);
                typeof window !== 'undefined' && localStorage.setItem('gtoken', credential.idToken)
                setReady(true)
            }
            setCheckedForRedirect(true)
        });
    }, [])

    return ready ? (
        <>
            {children}
        </>
    ) : null
}
